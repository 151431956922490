import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Section = styled.div`
  will-change: transform;
`

function Reveal({ offset = "1%", children }) {
  const ref = useRef()

  if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
  }

  useEffect(() => {
    if (offset !== 0) {
      gsap.fromTo(
        ref.current,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          scrollTrigger: {
            trigger: ref.current,
            // once: true,
            start: `top bottom-=${offset}`,
            // markers: true,
            toggleActions: "play none none reverse",
          },
          duration: 0.5,
          autoAlpha: 1,
          y: 0,
        }
      )
    }

    /*
    
        ScrollTrigger.batch(ref.current, {
      toggleClass: "animate__animated",
      markers: true,
    })

    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          // autoAlpha: 0,
          y: 100,
        },
        {
          duration: 0.3,
          // autoAlpha: 1,
          y: 0,
          ease: "none",
          scrollTrigger: {
            trigger: el.parentElement,
            start: "top bottom-=10%",
            markers: true,
            toggleActions: "play none none reverse",
          },
        }
      )
    })
    */
  }, [offset])

  return (
    <Section className="reveal" ref={ref}>
      {children}
    </Section>
  )
}

Reveal.defaultProps = {}

Reveal.propTypes = {}

export default Reveal
